<template>
  <div id="InformationFill">
    <div class="main-theme">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230605145119868.png" />
    </div>
    <yellowTitle style="margin-top: 20px" info="填写资料" :not-positon="true"></yellowTitle>
    <div class="fill_box">
      <div class="fill_box_title">姓名</div>
      <div class="fill_box_content_box">
        <input v-model="agentName" class="fill_box_content_box_input" type="text" placeholder="请输入姓名">
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">性别</div>
      <div class="fill_box_content_box">
        <div @click="changeSex(1)" style="margin-left: 20px" class="choose_sex">男
          <van-icon :name="sex == 1 ? 'checked' : 'circle'" color="#1AC1F3" />
        </div>
        <div @click="changeSex(2)" style="margin-left: 30px" class="choose_sex">女
          <van-icon :name="sex == 2 ? 'checked' : 'circle'" color="#1AC1F3" />
        </div>
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">民族</div>
      <div @click="showMinzu = !showMinzu" class="fill_box_content_box fill_box_content_box_2">
        <div class="xuanze_words">{{ minZuIndex >= 0 ? minZus[minZuIndex] : '请选择' }}</div>
        <van-icon name="arrow" color="#1AC1F3" size="16" />
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">电话</div>
      <div class="fill_box_content_box">
        <input v-model="phone" class="fill_box_content_box_input" type="number" placeholder='请填写您在"蔓薇健康"小程序的手机号'>
      </div>
    </div>
    <div class="telphone">
      <div class="fill_box">
        <div class="fill_box_title">验证码</div>
        <div class="fill_box_content_box">
          <input v-model="validCode" class="fill_box_content_box_input" type="number" placeholder="请输入验证码">
        </div>
      </div>
      <div class="send-code" @click="sendMsgCode()">{{ lastNum > 0 ? `${lastNum}s后重新发送` : '发送验证码' }}</div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">身份证号</div>
      <div class="fill_box_content_box">
        <input v-model="agentIdCard" class="fill_box_content_box_input" type="text" placeholder="请填写您的身份证号码">
      </div>
    </div>
    <div @click="show = true" class="fill_box">
      <input v-model="area" disabled placeholder="请选择所在地区" class="fill_box_input" type="text">
    </div>

    <van-popup v-model="show" round position="bottom">
      <van-cascader v-model="area" title="请选择所在地区" :options="cityData" @close="closeCascader" @finish="onFinish"
        active-color="#004E8F" />
    </van-popup>
    <div class="fill_box">
      <input v-model="address" placeholder="详细地址：如道路、门牌号、小区、单元室等" class="fill_box_input" type="text">
    </div>
    <div :class="familyType == 2 ? 'box box_2 box_3' : 'box box_2'">
      <yellowTitle info="选择服装"></yellowTitle>
      <div class="box_2_tips">*该服装属于定制产品，选择后无法更换，请谨慎选择</div>
      <div class="box_2_words">蜜蛋定制防晒服：</div>
      <van-radio-group class="size-list" v-model="sizeType">
        <div class="size-item" v-for="(it, index) in allSizeList" :key="index">
          <label>{{ it.title }}</label>
          <van-radio :name="text" v-for="(text, idx) in it.child" :key="idx" checked-color="#1AC1F3">{{ text
          }}</van-radio>
        </div>
      </van-radio-group>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230605150547393.png" class="chima" alt="">
    </div>
    <div @click="changeAgree" class="agree_box">
      <div :class="agree ? 'circle_agree circle_agree_focus' : 'circle_agree'"></div>
      <div class="words_agree">*我已阅读并同意
        <span @click.stop="showProtocol = true">会议免责声明</span>和
        <span @click.stop="showProtocol = true">青海旅游报名安全注意事项</span>，此项会议免责声明将在现场签署，请认真阅读后报名*
      </div>
    </div>
    <div @click="submit" class="sure_btn">确认提交</div>
    <div class="sure_words">*本人提交成功后支持家属报名申请*</div>
    <van-picker title="" v-show="showMinzu" style="position: fixed; bottom: 0; left: 0; width: 100%;" show-toolbar
      :columns="minZus" @confirm="chooseMinzu" @cancel="onCancel" />
    <van-popup v-model="showProtocol" position="top" :style="{ height: '80%' }">
      <protocol @close="onProtoClose" />
    </van-popup>
  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
import protocol from "./components/protocol.vue"
import { cityData4 } from "../../../utils/city.data-4";
import { Subtr } from "../../../utils/handle"
import { trim, regTel } from "../../../utils/public"

export default {
  name: "InformationFill",
  components: { yellowTitle, protocol },
  data() {
    return {
      allSizeList: [
        { id: 1, title: "成人尺码选择", child: ["L", "2XL", "4XL"] },
      ],
      sizeType: '',
      sex: 2,
      agree: false,
      info: '',
      agentName: '',
      agentPhone: '',
      agentIdCard: '',
      agentCode: '',
      area: '',
      address: '',
      jifen: '',
      minZus: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族',
        '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族',
        '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族',
        '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族',
        '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族',
        '基诺族'],
      minZuIndex: -1,
      showMinzu: false,
      familyType: 1,
      phone: '',
      cityData: cityData4,
      show: false,
      showProtocol: false,
      lastNum: 0,
      codeId: "",
      validCode: "",
      endTime: 60
    }
  },
  methods: {
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetUserDetails', { source: 3 }, true, 2).then(data => {
        if (data.code == 0) return this.$dialog.alert({ title: '提示', message: data.msg }).then(() => {
          this.$router.push("/")
        })
        this.info = data.response
        if (this.familyType === 2 && !data.response.existsApply) {
          this.$dialog.alert({ title: '提示', message: '本人报名成功后才支持家属报名噢！' }).then(() => {
            this.$router.push("/miSignUp")
          })
        }
      })
    },
    getAddressCode() {
      this.get("/staticSourceTemp/FixedFile/city.data-export.json", {}, 3).then(res => {
        this.cityData = res.cityData4;
      });
    },
    closeCascader() {
      this.show = false
      this.area = ''
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.area = selectedOptions.map((option) => option.text).join('/');
    },
    sendMsgCode() {
      if (this.lastNum > 0) return;
      let tel = trim(this.phone);
      if (!tel) return this.$dialog.alert({ title: "提示", message: '手机号码不能为空!' });
      if (!regTel(tel)) return this.$dialog.alert({
        title: "提示",
        message: '手机号格式不正确!'
      });
      this.$toast.loading({ message: '正在发送...', duration: 0, mask: true });
      this.post("/CommAPI/Comm/SendPhoneCode_V2", {
        phone: tel,
        type: 1,
        nationCode: "86"
      }, true, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.lastNum = 60;
          this.codeId = json.response.codeID
          let storage = window.localStorage;
          let obj = JSON.parse(storage.getItem(tel)) || {};
          let count = obj.count || 0;
          obj.count = count + 1;
          storage.setItem(tel, JSON.stringify(obj));
          let keyword = "miSignLastTime";
          window.localStorage[keyword] = (+new Date());
          this.countDown();
          this.$dialog.alert({ title: "提示", message: '验证码已发送至手机,请注意查收!' });
        } else {
          this.$dialog.alert({ title: "提示", message: json.msg });
        }
      });
    },
    //验证码获取倒计时
    countDown: function () {
      let last_time = window.localStorage['miSignLastTime'];
      let time_distance = (+new Date()) - last_time;
      /* 时间差：活动结束时间减去当前时间  */
      let int_second = Math.floor(time_distance / 1000);
      let downTime = this.endTime - int_second;
      if (downTime > 0) {
        setTimeout(() => {
          this.countDown();
        }, 1000);
        this.lastNum = downTime;
      } else {
        this.lastNum = 0;
        window.localStorage.removeItem("miSignLastTime");
      }
    },
    submit() {
      if (!this.agentName) {
        return this.$dialog.alert({ title: '提示', message: '请填写姓名' })
      }
      if (this.minZuIndex < 0) {
        return this.$dialog.alert({ title: '提示', message: '请选择民族' })
      }
      if (!this.phone) {
        return this.$dialog.alert({ title: '提示', message: '请填写正确的电话号码' })
      }
      if (!this.validCode) {
        return this.$dialog.alert({ title: '提示', message: '请填写手机验证码' })
      }
      if (!this.agentIdCard) {
        return this.$dialog.alert({ title: '提示', message: '请填写正确的身份证号' })
      }
      if (this.area == '') {
        return this.$dialog.alert({ title: '提示', message: '请选择地区' })
      }
      if (this.address == '') {
        return this.$dialog.alert({ title: '提示', message: '请填写地址' })
      }
      if (!this.agree) {
        return this.$dialog.alert({ title: '提示', message: '请阅读并同意旅游协议' })
      }
      if (!this.sizeType) return this.$dialog.alert({ title: '提示', message: '请选择服装' });
      this.sureSubmit();
    },
    sureSubmit() {
      this.$toast.loading({ message: '请稍后', duration: 0 })
      this.post('/OnlyOnce/AnnualMeeting/SignUpQingHai', {
        source: 3,
        signupType: this.familyType,
        name: this.agentName,
        minZu: this.minZus[this.minZuIndex],
        sex: this.sex,
        phone: this.phone,
        idCard: this.agentIdCard,
        clothStr: this.sizeType,
        addrStr: this.area.split('/').join('') + this.address,
        codeId: this.codeId,
        code: this.validCode
      }, true, 2).then(data => {
        this.$toast.clear()
        if (data.code == 1) {
          this.$dialog.alert({
            title: '提示',
            message: '申请成功！请稍后查看审核情况噢。'
          }).then(data => {
            this.$router.push('/miMeetingList?')
          })
        } else {
          this.$dialog.alert({
            title: '提示',
            message: data.msg
          })
        }
      })
    },
    chooseMinzu(value, index) {
      this.minZuIndex = index
      this.showMinzu = !this.showMinzu
    },
    onCancel() {
      this.showMinzu = !this.showMinzu
    },
    changeSex(e) {
      this.sex = e
    },
    changeAgree() {
      this.agree = !this.agree
    },
    onProtoClose() {
      this.agree = true;
      this.showProtocol = false;
    }
  },
  created() {
    this.Init()
    this.countDown();
    // this.getAddressCode();
    this.familyType = +this.$route.params.type;
  },
  mounted() {
    window.scroll({ top: 0 })
  }
}
</script>

<style lang="scss">
.main-theme {
  img {
    width: 100%;
  }
}

#InformationFill {
  padding-bottom: 30px;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 0;
  background-position: 0px -3px;
  background-color: #1AC1F3;

  input:disabled {
    background-color: transparent;
  }


  .box {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #fff;
    padding: 25px 10px 10px 10px;
    position: relative;
  }

  .box_2 {
    font-size: 12px;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    .box_2_tips {
      color: #1AC1F3;
      // text-align: start;
    }

    .box_2_words {
      text-align: start;
      color: #333;
      padding-top: 16px;
    }

    .line {
      margin-top: 3px;
      height: 1px;
      background-color: #ccc;
    }

    .chima {
      width: 100%;
      margin-top: 10px;
    }

    .size-list {
      padding-bottom: 10px;

      .size-item {
        display: flex;
        padding: 5px 0;
        align-items: center;

        label {
          color: #333;
        }

        .van-radio {
          margin-right: 10px;
          flex-direction: row-reverse;

          .van-radio__label {
            margin-right: 10px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .box_1 {
    color: #666666;
    padding-left: 20px;
    padding-right: 20px;

    .van-radio-group {
      padding-top: 10px;

      .van-radio {
        padding: 5px 0;

        .van-radio__icon {
          font-size: 19px;
          margin-right: 10px;

          &:not(.van-radio__icon--disabled) {
            .van-icon {
              border-color: #f0169a;
            }
          }
        }

        .van-radio__label {
          color: #666;
          font-size: 14px;
        }
      }
    }

    .line {
      margin-top: 22px;
      height: 1px;
      background-color: #ccc;
    }

    .deduction-list {
      .de-item {
        position: relative;
        padding-bottom: 18px;

        &:after {
          content: '';
          position: absolute;
          left: 15px;
          right: 15px;
          height: 1px;
          bottom: 0;
          background-color: #ccc;
        }

        &:last-child {
          &:after {
            height: 0;
          }
        }
      }
    }

    .words {
      margin-top: 18px;
      text-align: left;
      color: #333;
    }

    .input_box {
      display: flex;
      margin-top: 10px;

      .jifen_input {
        flex: 1;
        background-color: #fff0f9;
        border-radius: 5px 0 0 5px;
        border: none;
        font-size: 10px;
        box-sizing: border-box;
        padding-left: 15px;
      }

      .max_btn {
        width: 60px;
        height: 34px;
        background-color: #ff3cb2;
        border-radius: 0 5px 5px 0;
        text-align: center;
        line-height: 34px;
        color: #fff;
      }
    }

    .words_2 {
      color: #f0169a;
      margin-top: 10px;
      text-align: center;

      .zero_words {
        font-size: 24px;
        display: inline-block;
        margin-left: 1px;
        margin-right: 1px;
        position: relative;
        top: 1px;
      }
    }
  }

  .box_3 {
    .box_2_words {
      color: #999;
    }
  }



  .fill_box {
    margin-top: 12px;
    display: flex;
    border-radius: 10px;
    background-color: #fff;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
    color: #333333;
    margin-left: 20px;
    margin-right: 20px;

    .fill_box_input {
      height: 34px;
      flex: 1;
      border: none;
    }

    .fill_box_title {
      min-width: 70px;
      text-align: start;
    }

    .fill_box_content_box {
      height: 34px;
      flex: 1;
      display: flex;

      .fill_box_content_box_input {
        width: 100%;
        border: none;
      }

      .choose_sex {
        display: flex;
        align-items: center;
        color: #666;

        .van-icon {
          font-size: 18px;
          margin-left: 4px;
        }
      }
    }

    .fill_box_content_box_2 {
      justify-content: flex-end;
      align-items: center;

      .xuanze_words {
        color: #999;
      }

      .arrow_icon {
        width: 7px;
        height: 13px;
        margin-left: 8px;
      }
    }
  }

  .telphone {
    display: flex;
    padding-right: 15px;
    align-items: center;

    .fill_box {
      flex: 1;
    }

    .send-code {
      width: 107px;
      height: 34px;
      color: #209DFF;
      font-size: 12px;
      margin-top: 12px;
      line-height: 34px;
      border-radius: 10px;
      background-color: #fff;
    }
  }

  .libao {
    width: 94%;
    margin-top: 15px;
  }

  .agree_box {
    display: flex;
    color: #fff;
    font-size: 14px;
    width: 94%;
    margin-top: 10px;

    .circle_agree {
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 8px;
      margin-left: 20px;
    }

    .words_agree {
      flex: 1;
      text-align: start;

      span {
        text-decoration: underline;
      }
    }

    .circle_agree_focus {
      background-image: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230322144855778.png");
      background-size: 110%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
    }
  }

  .sure_btn {
    // background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210203152926153.png");
    width: 70%;
    height: 34px;
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    border-radius: 34px;
    transform: translateX(-50%);
    background-color: #209DFF;
  }

  .sure_words {
    font-size: 14px;
    max-width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    margin-top: 5px;
  }
}
</style>