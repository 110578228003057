<!--  -->
<template>
  <div class="container">
    <div class="main-title">青海六天五晚旅游 &lt;免责声明&gt;</div>
    <div class="remark">主办方特别提醒：请所有参加活动的人员在报名前务必认真、仔细阅读以下声明，在您签名后即视为您已阅读、理解并自愿遵守以下免责声明的全部内容。</div>
    <div class="pro-item">
      <p>本人作为一名身体健康的成年人，具有完全民事行为能力，我本人、我的法定代理人以及任何可能代表我提起赔偿请求或诉讼的人作出如下声明：</p>
      <p>一、本人已知悉本次活动的全部行程，本人身体健康，无任何不适宜参加本次活动的身体状况，经审慎考虑，自愿（或携带未成年人）参加 四川蜜蛋科技有限公司（以下简称“主办方”）组织的活动，并基于本人的真实意思表示作出本声明和保证。</p>
      <p>二、在此次会议活动中，如本人（及携带的未成年人）须遵从主办方工作人员的统一指引和管理，不得擅自擅自改变行程。</p>
      <p>三、本人已知悉活动参加者众多，存在一定的安全风险，这些风险所导致的本人（及携带的未成年人）人身、财产及/或导致第三方的人身、财产上的损失将由本人自行承担，主办方无需就本人（及携带的未成年人）的任何安全事故或财产损失承担任何经济责任及/或法律责任。</p>
      <p>四、本人同意：主办方有权根据活动的实际情况，要求本人退出活动。本人应遵守主办方的安排的退出行程。</p>
      <p>五、一旦报名成功，则不允许取消或者退换名额。</p>
      <p>本人确认紧急联系方式如下（以下填能随时联系到的亲属）：</p>
      <p>联系人姓名： 联系电话：</p>
      <p>联系地址： 与本人的关系：</p>
      <p>本声明自本人签字之日起生效，本人已清楚阅读并充分理解和同意免责声明上的所有条款。</p>
      <p>签名：_______________ 身份证号：________________</p>
      <p>（18岁以下活动参加者的监护人同时签名） </p>
      <p>日期：_______________ 联系电话：_______________</p>
    </div>
    <van-divider />
    <div class="pro-item">
      <p>青海六天五晚旅游安全注意事项</p>
      <p>为了增强参会者安全意识，普及安全基本常识。使该次活动圆满、愉快、顺利地完成，请参会者认真阅读并切实遵守以下规则。</p>
      <p>一.乘车(机、船)安全事项</p>
      <p>1.参会者在机、车、船停稳后方可下机、车、船。按先后秩序排队上下机、车、船，要讲究文明礼貌，优先照顾老人，儿童，妇女。切勿拥挤，以免发生意外。</p>
      <p>2.参会者在乘车过程中，请不要与司机交谈和催促司机开快车，违章超速和超车行驶。不要将头、手、脚或行李物品伸出窗外，以防意外发生。</p>
      <p>3.参会者下车浏览、就餐、购物时，请注意关好旅游车窗，拿完自己随身携带的贵重物品。若出现遗失被盗会议主办方概不负责。</p>
      <p>4.参会者乘坐飞机时，应注意遵守民航乘机安全管理规定。应带好自己的有效身份证(小孩应带户口本原件，满 16岁未办理身份证的小孩需持派出所证明原件)，并注意保存好。</p>
      <p>5.不要向车窗外扔废(杂)物品(特别是硬质物品)，以免伤害他人。</p>
      <p>二、住宿安全事项</p>
      <p>1.参会者入住酒店后，应了解酒店安全须知，熟悉酒店的太平门、安全出路、安全楼梯的位置、安全转移的路线。</p>
      <p>2.注意检查酒店为你所配备的用品是否齐全，有无破损，如有不全或破损，请立即向酒店服务员或会议工作人员报告。</p>
      <p>3.贵重物品应存放于酒店服务总台保险柜，不要随身携带或放在房间内，若出现遗失后果自负。</p>
      <p>4.不要将自己住宿的酒店、房号随便告诉陌生人;不要让陌生人或自称酒店的维修人员随便进入房间；出入房间要锁好房门，睡觉前注意房门窗是否关好，保险锁是否锁上；物品最好放在身边，不要放在靠窗的地方。</p>
      <p>5.参会者入住酒店后需要外出时，应告知会议工作人员;在酒店总台领一张酒店信息卡，卡片上有饭店地址、电话，如果您迷路时，可以按卡片地址询问或搭乘出租车，安全顺利返回住所。</p>
      <p>6.遇紧急情况，千万不要慌张。发生火警时不要搭乘电梯或随意跳楼;镇定地判断火情，主动地实行自救。若身上着火，可就地打滚或用重衣物压火苗；必须穿过有浓烟的走廊、通道时，用浸湿的衣物披裹身体，捂着口鼻，贴近地、顺墙爬走；大火封门无法逃出时，可采用浸湿的衣物披裹身体，被褥堵门缝或泼水降温的办法等待救援或摇动色彩鲜艳的衣物呼唤救援人员。</p>
      <p>二.饮食卫生安全事项</p>
      <p>1.在本次行程中购买食物需注意商品质量，不要购买“三无”(无生产厂、生产日期、厂家地址)商品。发现食物不卫生或有异味变质的情况，切勿食用。</p>
      <p>2.出门游玩时，应随身带上矿泉水及干粮等食品，以备不时之需，注意请勿喝生水和不洁净之水。不要接受和食用陌生人赠送的香烟、食物和饮品，防止他人暗算。</p>
      <p>3.本次行程中要合理饮食、不要暴饮、暴食。喜欢喝酒的参会者在旅途中应控制自己的酒量，饮酒时最好不要超过本人平时酒量的三分之一；若出现酗酒闹事、扰乱社会秩序、侵犯他人所造成的一切损失由肇事者本人承担。</p>
      <p>4.为防止在旅途中水土不服，参会者应自各备一些常用的药品，以备不时之需。切勿随意服用他人所提供之药品。</p>
      <p>四.游览观景安全事项</p>
      <p>1.听取现场工作人员有关安全的提示和忠告，主要应预防意外事故和突发性疾病的发生。</p>
      <p>2.经过危险地段(如陡峭、狭窄的山路、潮湿泛滑的道路等)不可拥挤，前往险峻处观光时应充分考虑自身的条件是否可行，不要强求和存侥幸心理。</p>
      <p>3.登山或参与活动中应注意适当休息，避免过度激烈活动，同时做好预防工作。</p>
      <p>4.在水上(包括江河、湖海、水库)游览或活动时，注意乘船安全，要穿戴救生衣。切勿单独前往深水水域或危险河道。</p>
      <p>5.乘坐缆车或其他载人观光运载工具时，应服从景区工作人员安排。遇超载、超员或其他异常时，千万不要乘坐，以防发生危险。</p>
      <p>6.游览期间，参会者应三两成群，不要独行。如果迷失方向，原则上应原地等候，并及时打电话求救，千万不要着急。自由活动期间游客不要走得太远。带小孩和老人的参会者，请管好自己的小孩，不能让小孩单独行动，并注意安全。</p>
      <p>五.购物娱乐安全事项</p>
      <p>1.不要轻信流动推销人员的商品推荐。</p>
      <p>2.无意购买时，不要向商家问价或还价。</p>
      <p>3.不要随商品推销人员到偏僻地方购物或取物。</p>
      <p>4.要细心鉴别商品真伪，不要急于付款购物、购物时应向商家索取正式发票。</p>
      <p>5.在热闹拥挤的场所购物或娱乐时，注意保管好自己的钱包、提包、贵重的物品及证件。不要单独行动，不要前往管理混乱的娱乐场所。在购物、娱乐时，主要应防止被诈骗、盗窃和抢劫事故的发生。</p>
      <p>6.在景点内娱乐时，应根据自身的条件参与适应的项目;在自由活动期间外出娱乐活动时，不要单独行动，不要前往管理混乱的娱乐场所。</p>
      <p>六.其他安全注意事项</p>
      <p>1.在参会和游览活动中，为了防止火灾事故的发生，请游客不要携带易燃、易爆物品。不要乱扔烟头和火种。遵守交通运输部门、酒店等有关安全管理规定及各种法律、法规。</p>
      <p>2.参会者在旅途中发生人身或财产意外事故时,按有关机构(如交通运输部门、酒店、保险公司、风景区管理单位等)订立的条例或合同规定处理或公安部门查处。本次会议主办方尽力提供必要的协助。</p>
      </div>
    <div class="agree-btn">
      <van-button round color="linear-gradient(to right, #1ac1f3, #209dff)"
        @click="onClose">阅读并同意《免责声明》《报名安全注意事项》</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    onClose() {
      this.$emit("close")
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  color: #666;
  font-size: 12px;

  .main-title {
    padding: 11px 15px 0;
    font-size: 18px;
    font-weight: bold;
  }
  .remark{
    padding: 11px 15px 0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: underline;
    text-indent: 16px;
  }

  .pro-item {
    padding: 11px 15px;
    letter-spacing: 1px;
    line-height: 20px;
    // border-bottom: 1px solid #999;

    // &:last-child {
    //   border-bottom: none;
    // }
  }

  .agree-btn {
    text-align: center;
    padding: 20px 0 30px;

    .van-button {
      font-size: 14px;
      box-shadow: 0 4px 0 #209dff;
    }
  }
}
</style>